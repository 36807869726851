<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">代付服务费账本</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <span style="height: 32px;line-height: 32px;font-size: 16px;color: #606266">余额</span>
      <span :style="{height: '32px',lineHeight: '32px',fontSize: '16px',marginLeft: '10px',
        color:balance===0?'#606266':(balance>0?'#67C23A':'#F56C6C')}">
        {{parseFloat(balance).toFixed(2)}}</span>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="accounts" :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              :style="{width: '701px'}" :cell-style="cellStyle">
      <el-table-column label="记账时间" prop="time" width="130" :resizable="false"
                       :formatter="timeFormat"/>
      <el-table-column label="收支金额" prop="cost" width="85" :resizable="false"
                       :formatter="costFormat"/>
      <el-table-column label="备注" prop="comment" width="400" :resizable="false"/>
      <el-table-column label="余额" prop="balance" width="85" :resizable="false"
                       :formatter="balanceFormat"/>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
  </div>
</template>

<script>
  export default {
    created () {
      this.getAccountList()
    },
    data () {
      return {
        query: {
          page: 1
        },
        balance: 0,
        accounts: [],
        total: 0
      }
    },
    methods: {
      timeFormat (row) {
        const date = new Date(row.time)
        const y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return y + '-' + MM + '-' + d + '　' + h + ':' + m
      },
      costFormat (row) {
        if (row.cost < 0) {
          return '－' + parseFloat(Math.abs(row.cost)).toFixed(2)
        } else if (row.cost > 0) {
          return '＋' + parseFloat(row.cost).toFixed(2)
        }
      },
      balanceFormat (row) {
        return parseFloat(row.balance).toFixed(2)
      },
      cellStyle ({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 1) {
          if (row[column.property] < 0) {
            return 'color: #F56C6C'
          } else if (row[column.property] > 0) {
            return 'color: #67C23A'
          }
        }
      },

      selectAccount () {
        this.query.page = 1
        this.getAccountList()
      },
      pageChange (page) {
        this.query.page = page
        this.getAccountList()
      },
      async getAccountList () {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getSystemBookAccountList',
            { params: this.query })

          if (!this.isLogin(res)) {
            loading.close()
            return
          }
          if (res.code !== 0) {
            this.balance = 0
            this.total = 0
            this.accounts = []
            loading.close()
            this.$msg.warning(res.msg)
            return
          }

          this.balance = res.data.count
          this.total = res.data.total
          this.accounts = res.data.data

          loading.close()
          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.balance = 0
          this.total = 0
          this.accounts = []
          loading.close()
          this.$msg.error(this.netError)
        }
      }
    }
  }
</script>

<style scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .dialogAdd {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 350px;
    height: 250px;
  }

  /deep/ .el-input-number .el-input__inner {
    text-align: left;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }
</style>
